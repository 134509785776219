<template>
  <ActionValidatorForm
      ref="form"
      v-model:external-results="externalResults"
      :validator="validator"
      class="code-login-validator-form"
      name="codeLogin"
      :submit="submit">
    <template #submit-label>
      {{ t('submit') }}
    </template>
    <template #actions>
      <slot name="actions" />
    </template>
    <CSRFToken />
    <FieldSet>
      <FormField
          :label="t('label')"
          :validator="validator.code"
          style="justify-self: center"
          type="wide"
          :wide-errors="true"
          :required="!!validator.code.required"
          name="code">
        <template #default="{id, fieldName}">
          <TextInput
              :id="id"
              ref="codeField"
              v-model.trim="validator.code.$model"
              :name="fieldName"
              :validator="validator.code"
              :size="6"
              class="code-login-validator-form__code"
              autofocus="autofocus"
              @update:model-value="validator.$clearExternalResults()" />
        </template>
        <template #error>
          <li
              v-if="validator.code.required.$invalid">
            {{ t('required') }}
          </li>
          <li
              v-if="validator.code.valid.$invalid">
            {{ t('invalid') }}
          </li>
          <li
              v-for="external in validator.code.$externalResults"
              :key="external.$uid">
            {{ external.$message }}
          </li>
        </template>
      </FormField>
    </FieldSet>
  </ActionValidatorForm>
</template>
<i18n>
{
  "nl": {
    "submit": "Log in met code",
    "label": "Ringcode",
    "invalid": "Code bestaat uit 6 cijfers",
    "required": "Vul een code in"
  },
  "en": {
    "submit": "Log in with code",
    "label": "Ring code",
    "invalid": "Code should be 6 digits",
    "required": "Please fill in a code"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import {
  onMounted,
  ref,
  useTemplateRef,
} from 'vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import gql from 'graphql-tag';
import { useMutation } from '@urql/vue';
import FieldSet from '../fieldsets/FieldSet.vue';
import CSRFToken from '../widgets/forms/CSRFToken.vue';
import TextInput from '../widgets/forms/TextInput.vue';
import FormField from '../fields/FormField.vue';
import ActionValidatorForm from '../forms/ActionValidatorForm.vue';
import {
  parseMutationResult,
  testExternal,
} from '../../utils/vuelidate.js';
import DEFAULT_OPERATION_RESPONSE from '../../queries/operation_response.graphql';

const CODE_LOGIN_MUTATION = gql`
mutation CodeLogin($code: String!) {
  ringAuthenticate(code: $code) {
    ...DefaultOperationResponse
    ... on RingUser {
      id
      ring {
        id
      }
    }
  }
}
${DEFAULT_OPERATION_RESPONSE}
`;

export default {
  components: {
    FieldSet,
    FormField,
    CSRFToken,
    TextInput,
    ActionValidatorForm,

  },
  emits: ['login'],
  setup(props, { emit }) {
    // refs
    const code = ref('');
    const externalResults = ref({});

    // uses
    const { t } = useI18n();
    const codeField = useTemplateRef('codeField');

    const validator = useVuelidate(
      {
        null: { testExternal },
        code: {
          required,
          valid: (v) => `${v}`.length === 6 && Number.parseInt(v, 10),
        },
      },
      { code },
      {
        $rewardEarly: true,
        $externalResults: externalResults,
      },
    );

    // mutations
    const { executeMutation: login } = useMutation(CODE_LOGIN_MUTATION);

    // methods
    const submit = async () => {
      const response = await login({ code: code.value });
      parseMutationResult(response);
      console.log(response);
      emit('login', response.data.ringAuthenticate.ring);
    };
    const focus = () => {
      codeField.value?.$el.focus();
    };

    onMounted(() => focus());

    return {
      // refs
      externalResults,

      // uses
      t,

      // validator
      validator,

      // methods
      submit,
      focus,
    };
  },
};
</script>
<style>
.code-login-validator-form {
  &__code {
    --input-font-size: 3rem;

    font-family: var(--font-monospace);

    font-weight: 400;
    letter-spacing: var(--dimension-small);

    text-align: center;
    font-variant-numeric: tabular-nums;
  }
}
</style>
