<template>
  <InsetPanel
      v-if="!user"
      class="login-view"
      :title="t('title')">
    <slot name="intro">
      <Paragraph>
        {{ t('intro') }}
      </Paragraph>
    </slot>

    <LoginValidatorForm ref="form" />

    <slot name="actions">
      <SectionPanel
          :title="t('reset')">
        <ActionPane>
          <Paragraph>
            {{ t('resetExplanation') }}
          </Paragraph>

          <template #buttons>
            <IconButton
                :to="{name: `${locale}:requestPasswordReset`}"
                type="secondary">
              {{ t('resetAccount') }}
            </IconButton>
          </template>
        </ActionPane>
      </SectionPanel>

      <SectionPanel
          :title="t('noAccount')">
        <ActionPane>
          <Paragraph>
            {{ t('noAccountExplanation') }}
          </Paragraph>

          <template #buttons>
            <IconButton
                :to="{name: `${locale}:register`}"
                type="secondary">
              {{ t('register') }}
            </IconButton>
          </template>
        </ActionPane>
      </SectionPanel>
    </slot>
  </InsetPanel>
</template>

<i18n>
{
  "nl": {
    "intro": "Met de inloggegevens van jouw account:",
    "noAccount": "Nog geen account?",
    "noAccountExplanation": "Indien je een sport wilt gaan beoefenen met jouw hond, kan je een account aanmaken.",
    "reset": "Wachtwoord vergeten?",
    "resetExplanation": "Dan kan je jouw wachtwoord opnieuw instellen met behulp van het e-mailadres.",
    "title": "Inloggen",
    "register": "Een account aanmaken",
    "resetAccount": "Wachtwoord opnieuw instellen"
  },
  "en": {
    "intro": "With your account details:",
    "noAccount": "No account?",
    "noAccountExplanation": "If you want to compete in a Dutch show, you can create an account.",
    "reset": "Forgot your password?",
    "resetExplanation": "You can reset your password with your e-mail address.",
    "title": "Log in",
    "register": "Create an account",
    "resetAccount": "Reset password"
  }
}
</i18n>
<script>
import {
  onMounted,
  useTemplateRef,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useHead } from '@unhead/vue';
import useSession from '../../session/session.js';
import SectionPanel from '../../components/panels/SectionPanel.vue';
import Paragraph from '../../components/typography/Paragraph.vue';
import IconButton from '../../components/buttons/IconButton.vue';
import InsetPanel from '../../components/panels/InsetPanel.vue';
import ActionPane from '../../components/panels/ActionPane.vue';
import LoginValidatorForm from '../../components/session/LoginValidatorForm.vue';

export default {
  components: {
    LoginValidatorForm,
    ActionPane,
    InsetPanel,
    Paragraph,
    SectionPanel,
    IconButton,
  },

  setup() {
    const form = useTemplateRef('form');

    // uses
    const { t, locale } = useI18n();
    const { user } = useSession();

    onMounted(() => {
      form.value?.focus();
    });

    useHead({
      title: t('title'),
    });

    return {
      // uses
      user,
      locale,
      t,
    };
  },

};
</script>

<style>
.login-view {
  align-self: center;
  justify-self: center;

  max-width: 25rem;
}
</style>
