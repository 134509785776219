<template>
  <IconButton
      :to="{
          name: `${locale}:login`,
          query: {
            next: (useLoginPath) ? route.path : undefined
          }
        }"
      type="secondary">
    <slot>
      {{ t('login') }}
    </slot>
  </IconButton>
</template>
<i18n>
{
  "nl": {
    "login": "Inloggen"
  },
  "en": {
    "login": "Log in"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import IconButton from '../buttons/IconButton.vue';

export default {
  components: { IconButton },
  props: {
    useLoginPath: {
      type: Boolean,
      default: () => true,
    },
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();

    return {
      t,
      locale,
      route,
    };
  },
};
</script>
