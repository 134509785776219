<template>
  <ActionValidatorForm
      ref="form"
      v-model:external-results="externalResults"
      :validator="validator"
      class="login-validator-form"
      name="login"
      :submit="submit">
    <template #submit-label>
      {{ t('submit') }}
    </template>
    <template #actions>
      <slot name="actions" />
    </template>
    <CSRFToken />
    <FieldSet>
      <FormField
          :label="t('field.email.label')"
          :validator="validator.username"
          :required="!!validator.username.required"
          name="username">
        <template #default="{id, fieldName}">
          <TextInput
              :id="id"
              ref="emailField"
              v-model.trim="validator.username.$model"
              :name="fieldName"
              :validator="validator.username"
              :size="60"
              autofocus="autofocus"
              type="email"
              @update:model-value="validator.$clearExternalResults()" />
        </template>
        <template #error>
          <li
              v-if="validator.username.required.$invalid">
            {{ t('field.email.required') }}
          </li>
          <li
              v-if="validator.username.email.$invalid">
            {{ t('field.email.required') }}
          </li>
        </template>
      </FormField>

      <FormField
          :label="t('field.password.label')"
          :validator="validator.password"
          :required="!!validator.password.required"
          name="password">
        <template #default="{id, fieldName}">
          <TextInput
              :id="id"
              v-model="validator.password.$model"
              :validator="validator.password"
              type="password"
              :size="60"
              :name="fieldName"
              @update:model-value="validator.$clearExternalResults()" />
        </template>
        <template #error>
          <li
              v-if="validator.password.required.$invalid">
            {{ t('field.password.required') }}
          </li>
        </template>
      </FormField>
    </FieldSet>
  </ActionValidatorForm>
</template>
<i18n>
{
  "nl": {
    "submit": "Log in",
    "field": {
      "email": {
        "invalid": "Vul een e-mailadres in",
        "label": "E-mailadres",
        "required": "Vul een e-mailadres in"
      },
      "password": {
        "label": "Wachtwoord",
        "required": "Vul een wachtwoord in"
      }
    }
  },
  "en": {
    "submit": "Log in",
    "field": {
      "email": {
        "invalid": "Please fill in an e-mail address",
        "label": "E-mail address",
        "required": "Please fill in an e-mail address"
      },
      "password": {
        "label": "Password",
        "required": "Enter the password"
      }
    }
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import {
  ref,
  useTemplateRef,
} from 'vue';
import {
  email,
  required,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import FieldSet from '../fieldsets/FieldSet.vue';
import CSRFToken from '../widgets/forms/CSRFToken.vue';
import TextInput from '../widgets/forms/TextInput.vue';
import FormField from '../fields/FormField.vue';
import ActionValidatorForm from '../forms/ActionValidatorForm.vue';
import { testExternal } from '../../utils/vuelidate.js';
import useSession from '../../session/session.js';

export default {
  components: {
    FieldSet,
    FormField,
    CSRFToken,
    TextInput,
    ActionValidatorForm,

  },
  setup() {
    // refs
    const username = ref('');
    const password = ref('');
    const externalResults = ref({});

    // uses
    const { t, locale } = useI18n();
    const { login } = useSession();
    const route = useRoute();
    const router = useRouter();
    const emailField = useTemplateRef('emailField');

    const validator = useVuelidate(
      {
        null: { testExternal },
        password: {
          required,
        },
        username: {
          email,
          required,
        },
      },
      { username, password },
      {
        $externalResults: externalResults,
        $rewardEarly: true,
      },
    );

    // methods
    const submit = async () => {
      const home = router.resolve({ name: `${locale.value}:home` }).fullPath;
      const next = await login(username.value, password.value, route.query.next || home);
      // Go to where the backend tells us to go
      if (next !== route.path) {
        await router.replace(next);
      }
    };
    const focus = () => {
      emailField.value?.$el.focus();
    };

    return {
      // refs
      externalResults,

      // uses
      t,

      // validator
      validator,

      // methods
      submit,
      focus,
    };
  },
};
</script>
